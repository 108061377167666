import { fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';

import envConfig from '../../../config/config';
import { sec } from '../../../security';

/**
 * Get an access token for the current user
 * @param args
 * @param api
 * @param extraOptions
 * @returns
 */
export const getAccessToken = retry(
  async (args, api, extraOptions) => {
    const result = await fetchBaseQuery({
      baseUrl: envConfig.apiBaseUrl + '/api/v2/',
      prepareHeaders: async (headers) => {
        const token = await sec.getAccessTokenSilently()();
        if (token) {
          headers.set('authorization', `Bearer ${token}`);
        }
        return headers;
      }
    })(args, api, extraOptions);

    // bail out of re-tries immediately if unauthorized,
    // because we know successive re-retries would be redundant
    // https://redux-toolkit.js.org/rtk-query/usage/customizing-queries#bailing-out-of-error-re-tries
    if (result.error?.status === 401) {
      retry.fail(result.error);
    }

    return result;
  },
  {
    // https://redux-toolkit.js.org/rtk-query/usage/customizing-queries#automatic-retries
    maxRetries: 0
  }
);
