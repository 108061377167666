// assets
import FolderIcon from '@mui/icons-material/Folder';
import PeopleIcon from '@mui/icons-material/People';
import ArchiveIcon from '@mui/icons-material/Archive';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

// Should mirror routes in src/routes/index.js
const admin = {
  title: 'Admin',
  type: 'group',
  id: 'admin-group',
  children: [
    {
      title: 'Users',
      type: 'item',
      id: 'users',
      url: '/admin/users',
      icon: PeopleIcon
    },
    {
      title: 'Invites',
      type: 'item',
      id: 'invites',
      url: '/admin/invites',
      icon: PersonAddIcon
    }
  ]
};

export default admin;
