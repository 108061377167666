import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Tooltip,
  Typography
} from '@mui/material';
import { RoleName, RolesResponse } from 'dd-client/types.gen';
import { roleIsHigherThanBaseRole } from './roleUtils';

interface RoleSelectorProps {
  selectedRole: RoleName;
  handleRoleChange: (event: SelectChangeEvent) => void;
  isLoadingRoles: boolean;
  rolesData: RolesResponse | undefined;
  currentUserProjectRole: RoleName | undefined;
}

/**
 * RoleSelector component that allows a user to select a role.
 * Options are limited to roles that the same or lower than the user's project role.
 * It is used in the ShareModal component to give a user their first ever role on a project.
 *
 * @param selectedRole - The role that is currently selected.
 * @param handleRoleChange - The function that handles the change of the selected role.
 * @param isLoadingRoles - Whether the roles are still loading.
 */
const RoleSelector = ({
  selectedRole,
  handleRoleChange,
  isLoadingRoles,
  rolesData,
  currentUserProjectRole
}: RoleSelectorProps) => {
  return (
    <FormControl fullWidth sx={{ width: 'fit-content' }}>
      <InputLabel id="role-select-label">Role</InputLabel>
      <Select
        labelId="role-select-label"
        id="role-select"
        value={selectedRole}
        label="Role"
        onChange={handleRoleChange}
        renderValue={(value) => <Typography>{value}</Typography>}
      >
        {isLoadingRoles ? (
          <MenuItem disabled value={selectedRole}>
            {/* TODO make this text blink, to indicate loading */}
            {selectedRole}
          </MenuItem>
        ) : (
          rolesData?.roles?.map((role) => {
            const disabled = roleIsHigherThanBaseRole(role.name, currentUserProjectRole);
            return (
              <MenuItem key={role.name} value={role.name} disabled={disabled}>
                <Tooltip
                  title={
                    disabled
                      ? `Cannot set the user's role higher than your own role: ${currentUserProjectRole}`
                      : ''
                  }
                  placement="left"
                  arrow
                >
                  <span>
                    <Stack direction="row" alignItems="center" spacing={1}>
                      <Typography>{role.name}</Typography>
                      <Tooltip title={role.description}>
                        <InfoOutlinedIcon fontSize="small" sx={{ fontSize: 16, color: 'text.secondary' }} />
                      </Tooltip>
                    </Stack>
                  </span>
                </Tooltip>
              </MenuItem>
            );
          })
        )}
      </Select>
    </FormControl>
  );
};

export default RoleSelector;
