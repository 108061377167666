import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import { CircularProgress, IconButton, Stack, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useProjectContext } from '../../../../pages/project/ProjectProvider';
import { usePatchProjectMutation } from '../../../../store/services/dueDiligenceServer';

const ProjectName = () => {
  const location = useLocation();
  const { project, setProject } = useProjectContext();
  const [isEditing, setIsEditing] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [name, setName] = useState(project?.name || '');
  const [patchProject, { isLoading }] = usePatchProjectMutation();

  useEffect(() => {
    setName(project?.name || '');
  }, [project]);

  if (!location.pathname.includes('/projects/')) {
    return null;
  }

  const handleSubmit = async () => {
    if (!project || name === project.name) {
      setIsEditing(false);
      setIsHovered(false);
      return;
    }

    try {
      const response = await patchProject({ id: project.id, name }).unwrap();
      setProject(response.project);
      setIsEditing(false);
      setIsHovered(false);
    } catch (error) {
      console.error('Failed to update project name:', error);
    }
  };

  const handleCancel = () => {
    setName(project?.name || '');
    setIsEditing(false);
    setIsHovered(false);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSubmit();
    } else if (event.key === 'Escape') {
      handleCancel();
    }
  };

  const handleClick = () => {
    setIsEditing(true);
  };

  if (!project?.name) {
    return null;
  }

  const projectName = (
    <Stack
      direction="row"
      spacing={1}
      alignItems="center"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={handleClick}
      sx={{ cursor: 'pointer' }}
    >
      <Typography variant="h3" sx={{ color: 'activeScheme.onPrimary' }}>
        {project.name}
      </Typography>
      {isHovered && <EditIcon sx={{ color: 'activeScheme.onPrimary' }} />}
    </Stack>
  );

  const iconButtonSx = {
    color: 'activeScheme.onPrimary',
    bgcolor: 'primary.light',
    '&:hover': {
      bgcolor: 'activeScheme.inversePrimary'
    }
  };

  const textField = (
    <Stack direction="row" spacing={1} alignItems="center">
      <TextField
        value={name}
        onChange={(e) => setName(e.target.value)}
        onKeyDown={handleKeyDown}
        autoFocus
        size="small"
        color="info"
        InputProps={{
          sx: {
            color: 'activeScheme.onPrimary',
            fontSize: '1.2rem',
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: 'activeScheme.onPrimary'
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: 'activeScheme.onPrimary',
              borderWidth: '2px'
            }
          }
        }}
      />
      <IconButton onClick={handleSubmit} size="small" sx={iconButtonSx}>
        {isLoading ? <CircularProgress size={24} sx={{ color: 'activeScheme.onPrimary' }} /> : <CheckIcon />}
      </IconButton>
      <IconButton onClick={handleCancel} size="small" sx={iconButtonSx}>
        <CloseIcon />
      </IconButton>
    </Stack>
  );

  return isEditing ? textField : projectName;
};

export default ProjectName;
