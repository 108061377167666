import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

import Loadable from '../components/Loadable';
import MainLayout from '../layout/MainLayout';

const Projects = Loadable(lazy(() => import('../pages/projects')));
const ProjectsArchive = Loadable(lazy(() => import('../pages/projects-archive')));
const ProjectCreatePage = Loadable(lazy(() => import('../pages/project-create')));
const Project = Loadable(lazy(() => import('../pages/project')));
const ProjectFilesSearch = Loadable(lazy(() => import('../pages/project/Search')));
const ProjectFiles = Loadable(lazy(() => import('../pages/project/Files')));
const ProjectReport = Loadable(lazy(() => import('../pages/project/Report')));
const ProjectChat = Loadable(lazy(() => import('../pages/project/Chat')));
const ProjectChecklist = Loadable(lazy(() => import('../pages/project/Checklist')));
const AuthenticatedRoute = Loadable(lazy(() => import('../components/auth0/AuthenticatedRoute')));
const Account = Loadable(lazy(() => import('../pages/admin/Account')));
const Users = Loadable(lazy(() => import('../pages/admin/Users')));
const User = Loadable(lazy(() => import('../pages/admin/User')));
const Invites = Loadable(lazy(() => import('../pages/admin/Invites')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  element: (
    <AuthenticatedRoute>
      <MainLayout />
    </AuthenticatedRoute>
  ),
  children: [
    {
      path: '/',
      element: <Navigate replace to="/projects" />
    },
    {
      path: '/projects',
      element: <Projects />
    },
    {
      path: '/projects_archive',
      element: <ProjectsArchive />
    },
    {
      path: '/projects/create',
      element: <ProjectCreatePage />
    },
    {
      path: '/projects/:id',
      element: <Project />,
      children: [
        {
          path: '',
          element: <Navigate to="files" replace />
        },
        {
          path: 'files',
          element: <ProjectFiles />
        },
        {
          path: 'search',
          element: <ProjectFilesSearch />
        },
        {
          path: 'report',
          element: <ProjectReport />
        },
        {
          path: 'chat',
          element: <ProjectChat />
        },
        {
          path: 'checklist',
          element: <ProjectChecklist />
        }
      ]
    },
    {
      path: '/admin',
      element: <Account />,
      children: [
        {
          path: '',
          element: <Navigate replace to="/admin/users" />
        },
        {
          path: 'users',
          element: <Users />
        },
        {
          path: 'invites',
          element: <Invites />
        }
      ]
    },
    {
      path: '/admin/users/:id',
      element: <User />
    },
    {
      path: '*',
      element: <Navigate to="/" replace />
    }
  ]
};

export default MainRoutes;
