import {
  Box,
  CircularProgress,
  Divider,
  FormControl,
  Select,
  SelectChangeEvent,
  Tooltip,
  Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import {
  RoleName,
  RoleResponse,
  UpdateProjectUsersPermissionsResponse,
  UserPermissionResponse
} from '../../../../dd-client/types.gen';
import { useGetUserBaseRoleQuery } from '../../../../store/services/auth0Proxy';
import {
  useRemoveUserPermissionsMutation,
  useSetUserPermissionsMutation
} from '../../../../store/services/permissions';

import { toast } from 'react-toastify';
import {
  REMOVE_ACCESS_ROLE_NAME,
  renderRemoveAccessMenuItem,
  renderRoleMenuItem
} from '../newUsers/RoleSelectMenuItems';

interface RoleSelectProps {
  projectId: string;
  userPermission: UserPermissionResponse;
  roles?: RoleResponse[];
  disabled?: boolean;
  currentUserProjectRole: RoleName | undefined;
  refreshProjectUsersAndRoles: () => void;
}
// Fudge factor to wait for FGA's eventual consistency. If this isn't long enough, the user will see the old role name. They can click refresh
const FUDGE_TIME = 3000;

/**
 * RoleSelect is a component that allows a user to select a role for a user on a project.
 * It is used in the ShareModal component.
 *
 * @param userPermission - The user permission to update.
 * @param projectId - The ID of the project.
 * @param roles - The roles to choose from.
 * @param disabled - Whether the component is disabled.
 * @param currentUserProjectRole - The role of the current user on the project.
 * @param refreshProjectUsersAndRoles - A function to refresh the project users and roles.
 */
const RoleSelector: React.FC<RoleSelectProps> = ({
  userPermission,
  projectId,
  roles = [],
  disabled = false,
  currentUserProjectRole,
  refreshProjectUsersAndRoles
}) => {
  if (userPermission.source === 'inherited') {
    return (
      <Tooltip
        arrow
        title="This user's role on this project is inherited from their organization role and cannot be changed."
      >
        <Typography>{userPermission.role_name}</Typography>
      </Tooltip>
    );
  }
  if (roles.length === 0 || !userPermission.user_id) {
    return null;
  }

  const [setUserPermissions] = useSetUserPermissionsMutation();
  const [removeUserPermissions] = useRemoveUserPermissionsMutation();
  const [isUpdatingUserPermissions, setIsUpdatingUserPermissions] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [allowedRoles, setAllowedRoles] = useState<RoleName[]>([]);

  const { data: userBaseRole } = useGetUserBaseRoleQuery(
    { userId: userPermission.user_id },
    {
      skip: !isOpen
    }
  );

  useEffect(() => {
    if (userBaseRole) {
      if (userBaseRole.role_name === 'Guest') {
        setAllowedRoles(roles.map((role) => role.name));
      } else {
        const indexOfUserBaseRole = roles.findIndex((role) => role.name === userBaseRole.role_name);
        const newAllowedRoles = roles.slice(0, indexOfUserBaseRole + 1).map((role) => role.name);
        setAllowedRoles(newAllowedRoles);
      }
    }
  }, [userBaseRole, roles]);

  const errorMessage = 'Failed to update user permissions. Please try again.';

  const handlePermissionResponse = (res: { data?: UpdateProjectUsersPermissionsResponse; error?: any }) => {
    if (res.error) {
      toast.error(errorMessage);
      return;
    }
    const response = res.data;
    if (!response) return;

    const { failed: failedUsers = [], succeeded: successfulUsers = [] } = response;

    if (successfulUsers.length > 0) {
      setTimeout(() => {
        toast.success('User permissions updated');
        refreshProjectUsersAndRoles();
        setIsUpdatingUserPermissions(false);
      }, FUDGE_TIME);
    }
    if (failedUsers.length > 0) {
      toast.error(`Failed to update user permissions`);
      setIsUpdatingUserPermissions(false);
    }
  };

  const handleError = () => {
    toast.error(errorMessage);
    setIsUpdatingUserPermissions(false);
  };

  const handleChange = (event: SelectChangeEvent) => {
    setIsUpdatingUserPermissions(true);
    setIsOpen(false);

    const newRole = event.target.value;
    const userId = userPermission.user_id;

    try {
      if (newRole === REMOVE_ACCESS_ROLE_NAME) {
        removeUserPermissions({
          projectId,
          userIds: [userId],
          roleName: userPermission.role_name
        })
          .then(handlePermissionResponse)
          .catch(handleError);
        return;
      }

      setUserPermissions({
        projectId,
        userIds: [userId],
        roleName: newRole as RoleName
      })
        .then(handlePermissionResponse)
        .catch(handleError);
    } catch (error) {
      handleError();
    }
  };

  return (
    <FormControl sx={{ minWidth: 120 }} size="small">
      <Select
        value={userPermission.role_name}
        onChange={handleChange}
        renderValue={(value) =>
          isUpdatingUserPermissions ? (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <CircularProgress size={16} sx={{ mr: 1 }} />
              {value}
            </Box>
          ) : (
            value
          )
        }
        disabled={disabled || isUpdatingUserPermissions}
        open={isOpen}
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
      >
        {roles.map((role) =>
          renderRoleMenuItem({
            key: role.id,
            role: role,
            userPermission,
            userBaseRoleName: userBaseRole?.role_name,
            allowedRoles,
            currentUserProjectRole
          })
        )}
        <Divider sx={{ my: 1 }} />
        {renderRemoveAccessMenuItem()}
      </Select>
    </FormControl>
  );
};

export default RoleSelector;
