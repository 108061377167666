// Framework
import PropTypes from 'prop-types';

// Components
import {
  Avatar,
  Box,
  ButtonBase,
  CardContent,
  ClickAwayListener,
  Paper,
  Popper,
  Stack,
  Typography
} from '@mui/material';
import Transitions from 'components/@extended/Transitions';
import MainCard from 'components/MainCard';
import ProfileTab from './ProfileTab';
// import SettingTab from './SettingTab';

// Assets

// Hooks
import { useAuth0 } from '@auth0/auth0-react';
import { useTheme } from '@mui/material/styles';
import { useRef, useState } from 'react';

// tab panel wrapper
function TabPanel({ children, value, index, ...other }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`profile-tabpanel-${index}`}
      aria-labelledby={`profile-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

// function a11yProps(index) {
//   return {
//     id: `profile-tab-${index}`,
//     'aria-controls': `profile-tabpanel-${index}`
//   };
// }

// ==============================|| HEADER CONTENT - PROFILE ||============================== //

const Profile = () => {
  const theme = useTheme();
  const { user, isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
  // user: {
  // email: 'lyon@threshpower.com';
  // email_verified: true;
  // family_name: 'Lay';
  // given_name: 'Lyon';
  // name: 'Lyon Lay';
  // nickname: 'lyon';
  // org_id: 'org_kvBAt0gFCIYuGDTu';
  // picture: 'https://lh3.googleusercontent.com/a/ACg8ocLv4AGgXnhOP_zCFFIhWWy2hpohaeacJdEc0V8USclVO7GL7g=s96-c';
  // sub: 'google-oauth2|114965157274607963514';
  // updated_at: '2024-09-19T03:31:08.285Z';
  // }
  // const [userMetadata, setUserMetadata] = useState(null);
  const displayName = user?.name;

  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const [value, setValue] = useState(0);

  // const handleChange = (event, newValue) => {
  //   setValue(newValue);
  // };

  /**
   * I don't understand why but getAccessTokenSilently() fails with 'Login Required' on firefox.
   * Which then logs the user out
   * Maybe something to do with refresh tokens?
   * I'm leaving the code here
   * - a) so I can come back and fix it later and
   * - b) as a warning to future attmepts at this.
   */
  // useEffect(() => {
  //   if (!user?.sub) return; // This did not fix the 'Login Required' issue
  //   const getUserMetadata = async () => {
  //     const domain = envConfig.auth0Config.domain;
  //     try {
  //       const accessToken = await getAccessTokenSilently({
  //         authorizationParams: {
  //           audience: `https://${domain}/api/v2/`,
  //           scope: 'read:current_user'
  //         }
  //       });
  //       const userDetailsByIdUrl = `https://${domain}/api/v2/users/${user.sub}`;
  //       const metadataResponse = await fetch(userDetailsByIdUrl, {
  //         headers: {
  //           Authorization: `Bearer ${accessToken}`
  //         }
  //       });
  //       const { user_metadata } = await metadataResponse.json();
  //       setUserMetadata(user_metadata);
  //     } catch (e) {
  //       console.warn(`Failed to get access token ${e.message}`);
  //     }
  //   };
  //   getUserMetadata();
  // }, [getAccessTokenSilently, user?.sub]);

  const avatar = <Avatar src={user?.picture} alt={user?.name.slice(0, 1)} />;
  const iconBackColorOpen = 'rgba(0, 0, 0, 0.1)';

  return (
    <Box sx={{ flexShrink: 0, ml: 0.75 }}>
      <ButtonBase
        sx={{
          p: 0.25,
          bgcolor: open ? iconBackColorOpen : 'transparent',
          borderRadius: 2,
          '&:hover': { bgcolor: 'rgba(0, 0, 0, 0.1)' }
        }}
        aria-label="open profile"
        ref={anchorRef}
        aria-controls={open ? 'profile-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <Stack direction="row" spacing={1} alignItems="center" sx={{ p: 0.5 }}>
          {avatar}
          {user && (
            <Typography variant="subtitle1" color="activeScheme.onPrimary">
              {user.name}
            </Typography>
          )}
        </Stack>
      </ButtonBase>
      <Popper
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 9]
              }
            }
          ]
        }}
      >
        {({ TransitionProps }) => (
          <Transitions type="fade" in={open} {...TransitionProps}>
            {open && (
              <Paper
                sx={{
                  boxShadow: theme.customShadows.z1,
                  width: 290,
                  borderRadius: 3,
                  minWidth: 140,
                  maxWidth: 290,
                  [theme.breakpoints.down('md')]: {
                    maxWidth: 250
                  }
                }}
              >
                <ClickAwayListener onClickAway={handleClose}>
                  <MainCard
                    elevation={0}
                    border={false}
                    content={false}
                    sx={{ backgroundColor: 'activeScheme.surfaceContainerLowest' }}
                  >
                    {/* <MainCard elevation={0} border={false} content={false} sx={{backgroundColor: 'red'}}> */}
                    <CardContent
                      sx={{
                        px: 2.5,
                        pt: 3,
                        borderBottom: 1,
                        borderColor: 'activeScheme.surfaceContainer'
                      }}
                    >
                      <Stack direction="row" spacing={1.25} alignItems="center">
                        {avatar}
                        <Stack>
                          <Typography variant="subtitle1" color="activeScheme.onSurface">
                            {user ? user.name : 'Profile'}
                          </Typography>
                          <Typography variant="subtitle2" color="activeScheme.onSurface">
                            {user?.email}
                          </Typography>
                        </Stack>
                      </Stack>
                    </CardContent>
                    {open && (
                      <>
                        {/* <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                          <Tabs variant="fullWidth" value={value} onChange={handleChange} aria-label="profile tabs">
                            <Tab
                              sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                                textTransform: 'capitalize'
                              }}
                              icon={<UserOutlined style={{ marginBottom: 0, marginRight: '10px' }} />}
                              label="Profile"
                              {...a11yProps(0)}
                            />
                            <Tab
                              sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                                textTransform: 'capitalize'
                              }}
                              icon={<SettingOutlined style={{ marginBottom: 0, marginRight: '10px' }} />}
                              label="Setting"
                              {...a11yProps(1)}
                            />
                          </Tabs>
                        </Box> */}
                        <TabPanel value={value} index={0} dir={theme.direction}>
                          <ProfileTab />
                        </TabPanel>
                        {/* <TabPanel value={value} index={1} dir={theme.direction}>
                          <SettingTab />
                        </TabPanel> */}
                      </>
                    )}
                  </MainCard>
                </ClickAwayListener>
              </Paper>
            )}
          </Transitions>
        )}
      </Popper>
    </Box>
  );
};

export default Profile;
