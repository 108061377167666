import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { MenuItem, Stack, Tooltip, Typography } from '@mui/material';
import { RoleName, RoleResponse, UserPermissionResponse } from '../../../../dd-client/types.gen';

import { roleIsHigherThanBaseRole } from './roleUtils';

interface RoleMenuItemProps {
  role: RoleResponse;
  userPermission: UserPermissionResponse;
  userBaseRoleName: RoleName | undefined;
  allowedRoles: RoleName[];
  key: string;
  currentUserProjectRole: RoleName | undefined;
}

function renderRoleMenuItem({
  role,
  userPermission,
  userBaseRoleName,
  allowedRoles,
  key,
  currentUserProjectRole
}: RoleMenuItemProps) {
  const roleName = role.name;
  const roleIsHigherThanCurrentUserProjectRole = roleIsHigherThanBaseRole(roleName, currentUserProjectRole);
  const roleNotInAllowedRoles = !allowedRoles.includes(roleName);
  const disabled = roleNotInAllowedRoles || roleIsHigherThanCurrentUserProjectRole;
  const menuItem = (
    <MenuItem key={key} value={roleName} disabled={disabled}>
      {roleName === userPermission.role_name ? (
        <RadioButtonCheckedIcon fontSize="small" sx={{ mr: 1, color: 'primary.main' }} />
      ) : (
        <RadioButtonUncheckedIcon fontSize="small" sx={{ mr: 1 }} />
      )}
      <Stack direction="row" spacing={1} alignItems="flex-end">
        <Typography>{roleName}</Typography>
        {userBaseRoleName === roleName && (
          <Typography variant="subtitle2" color="text.secondary">
            {'(Org Role)'}
          </Typography>
        )}
        {role.description && (
          <Tooltip title={role.description}>
            <InfoOutlinedIcon fontSize="small" sx={{ fontSize: 16, color: 'text.secondary' }} />
          </Tooltip>
        )}
      </Stack>
    </MenuItem>
  );

  if (!disabled) {
    return menuItem;
  }

  const tooltipTitle = roleIsHigherThanCurrentUserProjectRole
    ? `Cannot set user's role higher than your own role: ${currentUserProjectRole}`
    : `Cannot set user's role lower than their organization role: ${userBaseRoleName}`;

  return (
    <Tooltip arrow key={key} title={tooltipTitle} placement="left">
      <span>{menuItem}</span>
    </Tooltip>
  );
}

const REMOVE_ACCESS_ROLE_NAME = 'remove';

function renderRemoveAccessMenuItem() {
  return (
    <MenuItem value={REMOVE_ACCESS_ROLE_NAME} sx={{ color: 'error.main' }}>
      Remove Access
    </MenuItem>
  );
}

export { REMOVE_ACCESS_ROLE_NAME, renderRemoveAccessMenuItem, renderRoleMenuItem };
