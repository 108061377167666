import RefreshIcon from '@mui/icons-material/Refresh';
import {
  Avatar,
  Box,
  CircularProgress,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Skeleton,
  Stack,
  Tooltip,
  Typography
} from '@mui/material';
import { RolesResponse } from 'dd-client/types.gen';
import { RoleName, UserPermissionsResponse } from '../../../../dd-client/types.gen';
import RoleSelector from './RoleSelector';

const LoadingSkeleton = () => {
  return (
    <Stack direction="row" spacing={2} sx={{ width: '100%' }} alignItems="center">
      <Skeleton variant="circular">
        <Avatar></Avatar>
      </Skeleton>
      <Stack direction="column" spacing={1} sx={{ width: '100%', height: '100%' }}>
        <Skeleton variant="rounded" height={16} width={'70%'}>
          <Typography variant="h6" />
        </Skeleton>
        <Skeleton variant="rounded" height={16} width={'50%'}>
          <Typography variant="body2" />
        </Skeleton>
      </Stack>
    </Stack>
  );
};

interface ExistingUsersProps {
  projectId: string;
  currentUserProjectRole: RoleName | undefined;
  refreshProjectUsersAndRolesWrapper: () => void;
  isLoadingRelatedUsers: boolean;
  isLoadingRoles: boolean;
  relatedUsersData: UserPermissionsResponse | undefined;
  rolesData: RolesResponse | undefined;
}

const ExistingUsers = ({
  projectId,
  currentUserProjectRole,
  refreshProjectUsersAndRolesWrapper,
  isLoadingRelatedUsers,
  isLoadingRoles,
  relatedUsersData,
  rolesData
}: ExistingUsersProps) => {
  return (
    <Box sx={{ mt: 3 }}>
      <Stack direction="row" spacing={1} alignItems="center">
        <Typography variant="h5" gutterBottom>
          People with access
        </Typography>
        <Tooltip arrow title="Refresh to see the latest role assignments on this project">
          <IconButton onClick={refreshProjectUsersAndRolesWrapper} size="small">
            {isLoadingRelatedUsers ? <CircularProgress size={16} /> : <RefreshIcon fontSize="small" />}
          </IconButton>
        </Tooltip>
      </Stack>

      {isLoadingRelatedUsers || isLoadingRoles ? (
        <Stack direction="column" spacing={2} sx={{ my: 2 }}>
          <LoadingSkeleton />
          <LoadingSkeleton />
        </Stack>
      ) : (
        <List sx={{ mb: 3, maxHeight: '30vh', overflow: 'auto' }}>
          {relatedUsersData?.users.map((userPermission) => (
            <ListItem
              key={userPermission.user_id}
              secondaryAction={
                <RoleSelector
                  projectId={projectId}
                  roles={rolesData?.roles}
                  userPermission={userPermission}
                  disabled={isLoadingRoles}
                  currentUserProjectRole={currentUserProjectRole}
                  refreshProjectUsersAndRoles={refreshProjectUsersAndRolesWrapper}
                />
              }
            >
              <ListItemAvatar>
                <Avatar src={userPermission.user?.picture} alt={userPermission.user?.name || ''}>
                  {userPermission.user?.name?.charAt(0)}
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={userPermission.user?.name || userPermission.user_id}
                secondary={userPermission.user?.email}
              />
            </ListItem>
          ))}
        </List>
      )}
    </Box>
  );
};

export default ExistingUsers;
