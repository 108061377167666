import { createApi } from '@reduxjs/toolkit/query/react';
import {
  ChatTokenResponseModel,
  FileProcessingJobResponseModel,
  FileResponseModel,
  PatchFileModel,
  ProjectFilesResponseModel,
  ProjectResponseModel,
  ProjectsResponseModel,
  ProjectUpdateModel,
  ReportJobResponseModel,
  ReportResponseModel,
  SearchResponseModel,
  VectorStoreJobResponseModel
} from '../../dd-client/types.gen';
import { getAccessToken } from './utils/accessToken';

interface filePatchArgs {
  projectId: number;
  fileId: number;
  body: PatchFileModel;
}

export const dueDiligenceServer = createApi({
  reducerPath: 'dueDiligenceServer',
  baseQuery: getAccessToken,
  endpoints: (builder) => ({
    getProjects: builder.query<ProjectsResponseModel, any>({
      query: (archivedProjects = false) => {
        const queryParams = archivedProjects ? '?archived=true' : '';
        return `projects${queryParams}`;
      }
    }),
    createProject: builder.mutation({
      query: (project) => ({
        url: `projects`,
        method: 'POST',
        body: project
      }),
      extraOptions: { maxRetries: 2 }
    }),
    getProject: builder.query<ProjectResponseModel, any>({
      query: (projectId) => `projects/${projectId}`
    }),
    patchProject: builder.mutation<ProjectResponseModel, ProjectUpdateModel>({
      query: (body: ProjectUpdateModel) => ({
        url: `projects/${body.id}`,
        method: 'PATCH',
        body
      })
    }),
    patchFile: builder.mutation({
      query: ({ projectId, fileId, body }: filePatchArgs) => ({
        url: `projects/${projectId}/files/${fileId}`,
        method: 'PATCH',
        body
      })
    }),
    getProjectFile: builder.query<FileResponseModel, any>({
      query: ({ projectId, fileId, includeFileTypeSpecificFields = false, includeRelationships = false }) => {
        const queryParams = new URLSearchParams();
        if (includeFileTypeSpecificFields) {
          queryParams.append('include', 'file_type_specific_fields');
        }
        if (includeRelationships) {
          queryParams.append('include', 'relationships');
        }
        return `projects/${projectId}/files/${fileId}?${queryParams.toString()}`;
      }
    }),
    getProjectFilesUploaded: builder.query({
      query: (projectId) => `projects/${projectId}/files/uploaded`
    }),
    getProjectFilesOrganized: builder.query<ProjectFilesResponseModel, any>({
      query: (projectId) => `projects/${projectId}/files`
    }),
    deleteProjectFiles: builder.mutation({
      query: ({ projectId, fileIds }) => ({
        url: `projects/${projectId}/files`,
        method: 'DELETE',
        body: {
          file_ids: fileIds
        }
      })
    }),
    getProjectFileDownload: builder.mutation({
      query({ projectId, fileId, fileName, shouldDownload = false, getBlob = false, page = null }) {
        const query_params = page ? `?page=${page}` : '';
        return {
          url: `projects/${projectId}/files/${fileId}/download${query_params}`,
          method: 'GET',
          responseHandler: async (response: any) => {
            if (response.ok) {
              const blob = await response.blob();

              if (shouldDownload) {
                downloadBlob(blob, fileName);
              } else if (getBlob) {
                return blob;
              } else {
                return window.URL.createObjectURL(blob);
              }
            }
            return response.url;
          },
          cache: 'no-cache'
        };
      }
    }),
    getProjectFilesZip: builder.mutation({
      query({ projectId, start, end, filepath }) {
        return {
          url: `projects/${projectId}/files-zip`,
          method: 'POST',
          headers: {
            Range: `bytes=${start}-${end}`
          },
          body: {
            filepath
          },
          // TODO rm this somehow
          responseHandler: async (response: any) => response,
          cache: 'no-cache'
        };
      }
    }),
    getProjectFilesZipName: builder.mutation({
      query({ projectId }) {
        return {
          url: `projects/${projectId}/files-zip`,
          method: 'GET',
          cache: 'no-cache'
        };
      }
    }),
    getSelectedFilesZipName: builder.mutation({
      query: ({ projectId, fileIds, folderIds }) => ({
        url: `projects/${projectId}/generate-download-zip`,
        method: 'POST',
        cache: 'no-cache',
        body: {
          file_ids: fileIds,
          folder_ids: folderIds
        }
      })
    }),
    getProjectFilesSearch: builder.query<SearchResponseModel, any>({
      query: ({ projectId, query }) => `projects/${projectId}/file_search?query=${query}`
    }),
    getProjectJob: builder.query<FileProcessingJobResponseModel, any>({
      query: (projectId) => `projects/${projectId}/job`
    }),
    getProjectJobMutation: builder.mutation<FileProcessingJobResponseModel, any>({
      query: (projectId) => `projects/${projectId}/job`
    }),
    getProjectReportJob: builder.query<ReportJobResponseModel, number>({
      query: (projectId) => `projects/${projectId}/report-job`
    }),
    getProjectVectorStoreJob: builder.query<VectorStoreJobResponseModel, number>({
      query: (projectId) => `projects/${projectId}/vector-store-job`
    }),
    startJobForProject: builder.mutation<FileProcessingJobResponseModel, {projectId: number, batchKey: string}>({
      query: ({projectId, batchKey}) => {
        return {
          url: `projects/${projectId}/job`,
          method: 'POST',
          body: {batch_key: batchKey}
        }
      },
      extraOptions: { maxRetries: 2 }
    }),
    startReportGenerationJob: builder.mutation<ReportJobResponseModel, number>({
      query: (projectId) => ({
        url: `projects/${projectId}/report-job`,
        method: 'POST',
        body: {}
      }),
      extraOptions: { maxRetries: 2 }
    }),
    getProjectReport: builder.query<ReportResponseModel, number>({
      query: (projectId) => `projects/${projectId}/report`
    }),
    getProjectReportMutation: builder.mutation<ReportResponseModel, number>({
      query: (projectId) => `projects/${projectId}/report`
    }),
    moveFiles: builder.mutation({
      query: ({ projectId, source_file_ids = [], source_folder_ids = [], target_folder_id }) => ({
        url: `projects/${projectId}/files/move`,
        method: 'POST',
        body: {
          source_file_ids,
          source_folder_ids,
          target_folder_id
        }
      })
    }),
    // See archive/uploadFormData.ts for usage example
    uploadFormData: builder.mutation({
      query: ({ projectId, formData }) => ({
        url: `projects/${projectId}/files_raw`,
        method: 'POST',
        body: formData
      }),
      extraOptions: { maxRetries: 2 }
    }),
    generateChatUserToken: builder.query<ChatTokenResponseModel, any>({
      query: ({ userId }) => `chat/user/${userId}/token`
    })
  })
});

export const downloadBlob = (blob: any, filename: string) => {
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  a.remove();
  window.URL.revokeObjectURL(url);
};

// Export auto generated hooks
// https://redux-toolkit.js.org/rtk-query/api/created-api/hooks
export const {
  useGetProjectsQuery,
  useCreateProjectMutation,
  useStartJobForProjectMutation,
  useStartReportGenerationJobMutation,
  useGetProjectReportQuery,
  useGetProjectReportMutationMutation,
  useGetProjectQuery,
  usePatchProjectMutation,
  usePatchFileMutation,
  useGetProjectFilesOrganizedQuery,
  useGetProjectFilesUploadedQuery,
  useGetProjectFilesSearchQuery,
  useGetProjectFileQuery,
  useGetProjectJobQuery,
  useGetProjectJobMutationMutation,
  useGetProjectReportJobQuery,
  useGetProjectVectorStoreJobQuery,
  useGetProjectFilesZipMutation,
  useGetProjectFilesZipNameMutation,
  useGetSelectedFilesZipNameMutation,
  useGetProjectFileDownloadMutation,
  useDeleteProjectFilesMutation,
  useMoveFilesMutation,
  useUploadFormDataMutation,
  useGenerateChatUserTokenQuery
} = dueDiligenceServer;
