import { createApi } from '@reduxjs/toolkit/query/react';
import { RoleName, UpdateProjectUsersPermissionsResponse, UserPermissionsResponse } from '../../dd-client/types.gen';
import { getAccessToken } from './utils/accessToken';

/**
 * Endpoints for our Permissions API
 *
 */
export const permissions = createApi({
  reducerPath: 'permissions',
  baseQuery: getAccessToken,
  endpoints: (builder) => ({
    getProjectsRelatedUsersAndRoles: builder.query<
      UserPermissionsResponse,
      { projectId: string; includeUsers?: boolean; includeRoles?: boolean }
    >({
      query: ({ projectId, includeUsers = false, includeRoles = false }) => ({
        url: `/permissions/projects/${projectId}/users`,
        params: {
          include: [...(includeUsers ? ['user'] : []), ...(includeRoles ? ['role'] : [])]
        }
      })
    }),
    setUserPermissions: builder.mutation<
      UpdateProjectUsersPermissionsResponse,
      { projectId: string; userIds: string[]; roleName: RoleName }
    >({
      query: ({ projectId, userIds, roleName }) => ({
        url: `/permissions/projects/${projectId}/users`,
        method: 'POST',
        body: { user_ids: userIds, role_name: roleName }
      })
    }),
    removeUserPermissions: builder.mutation<
      UpdateProjectUsersPermissionsResponse,
      { projectId: string; userIds: string[], roleName: RoleName }
    >({
      query: ({ projectId, userIds, roleName }) => ({
        url: `/permissions/projects/${projectId}/users`,
        method: 'DELETE',
        body: { user_ids: userIds, role_name: roleName }
      })
    })
  })
});

// Export auto generated hooks
// https://redux-toolkit.js.org/rtk-query/api/created-api/hooks
export const {
  useGetProjectsRelatedUsersAndRolesQuery,
  useSetUserPermissionsMutation,
  useRemoveUserPermissionsMutation
} = permissions;
