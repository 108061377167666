import { useAuth0 } from '@auth0/auth0-react';
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  SelectChangeEvent,
  Stack,
  Tooltip
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { MemberResponse, RoleName } from '../../../dd-client/types.gen';
import { useGetOrganizationMembersQuery, useGetRolesQuery } from '../../../store/services/auth0Proxy';
import {
  permissions,
  useGetProjectsRelatedUsersAndRolesQuery,
  useSetUserPermissionsMutation
} from '../../../store/services/permissions';
import ExistingUsers from './existingUsers/ExistingUsers';
import RoleSelector from './newUsers/RoleSelector';
import UserSelect from './newUsers/UserSelect';

interface ShareModalProps {
  open: boolean;
  onClose: () => void;
  projectId: string;
  projectName: string;
}

/**
 * ShareModal component that allows users to share projects with others
 * Provides options for sharing via email or generating a shareable link
 */
const ShareModal: React.FC<ShareModalProps> = ({ open, onClose, projectId, projectName }) => {
  // Form state
  const [selectedUsers, setSelectedUsers] = useState<MemberResponse[]>([]);
  const [selectedRole, setSelectedRole] = useState<RoleName>('Editor');
  const [selectableUsers, setSelectableUsers] = useState<MemberResponse[]>([]);
  // Derivative state
  const [currentUserProjectRole, setCurrentUserProjectRole] = useState<RoleName | undefined>(undefined);
  // Requests
  const [setUserPermissions] = useSetUserPermissionsMutation();
  const [refreshProjectUsersAndRoles, { isFetching: isRefreshingRelatedUsers }] =
    permissions.endpoints.getProjectsRelatedUsersAndRoles.useLazyQuery();
  // Data: Current user, organization members, roles, and users with access to the project
  const { user, isLoading: authLoading } = useAuth0();
  const skip = !open || authLoading;
  const { data: membersData, isLoading: membersLoading } = useGetOrganizationMembersQuery(user?.org_id, {
    skip
  });
  const { data: rolesData, isLoading: isLoadingRoles } = useGetRolesQuery(
    { excludeSpecialRoles: true },
    { skip }
  );
  const { data: relatedUsersData, isLoading: isLoadingRelatedUsers } =
    useGetProjectsRelatedUsersAndRolesQuery(
      {
        projectId,
        includeUsers: true,
        includeRoles: false
      },
      { skip }
    );

  useEffect(() => {
    // Filter selectable users to exclude users that are already in the project
    if (membersData?.users) {
      let addableUsers = membersData.users;
      if (relatedUsersData?.users) {
        const userAlreadyOnProject = new Set(relatedUsersData.users.map((user) => user.user_id));
        addableUsers = addableUsers.filter((user) => !userAlreadyOnProject.has(user.user_id));
      }
      setSelectableUsers(addableUsers);
    }
    // Find the current user's role on the project
    if (relatedUsersData?.users) {
      setCurrentUserProjectRole(
        relatedUsersData.users.find((relatedUser) => relatedUser.user_id === user?.sub)?.role_name
      );
    }
  }, [membersData, relatedUsersData]);

  // Set default role when roles are loaded
  useEffect(() => {
    if (rolesData?.roles && rolesData.roles.length > 0 && !selectedRole) {
      setSelectedRole(rolesData.roles[0].name);
    }
  }, [rolesData]);

  // ********** Callbacks **********
  const refreshProjectUsersAndRolesWrapper = () => {
    refreshProjectUsersAndRoles({
      projectId,
      includeUsers: true,
      includeRoles: false
    });
  };
  const errorMessage = 'Failed to share project. Please try again.';
  const handleShare = async () => {
    try {
      const result = await setUserPermissions({
        projectId,
        userIds: selectedUsers.map((user) => user.user_id),
        roleName: selectedRole as RoleName
      });

      if (result.error || !result.data) {
        toast.error(errorMessage);
        return;
      }

      const { succeeded, failed } = result.data;
      toast.success(
        `Project shared with ${succeeded?.length} of ${selectedUsers.length} users. It may take a few seconds for this to take effect.`
      );

      if (failed?.length) {
        toast.error(`Failed to share project with ${failed.length} user(s)`);
      }

      setSelectedUsers([]);
      refreshProjectUsersAndRolesWrapper();
    } catch (error) {
      toast.error(errorMessage);
    }
  };

  const handleRoleChange = (event: SelectChangeEvent) => {
    setSelectedRole(event.target.value as RoleName);
  };

  if (!open) return null;

  const submitDisabled = selectedUsers.length === 0 || !selectedRole;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: { borderRadius: 2, maxWidth: 600 }
      }}
    >
      <DialogTitle
        variant="h4"
        sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
      >
        {`Share ${projectName}`}
        <IconButton onClick={onClose} size="small">
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        {/* Organization members sharing section */}
        <Box sx={{ mt: 1 }}>
          <Stack direction="row" spacing={2}>
            <UserSelect
              selectableUsers={selectableUsers}
              membersLoading={membersLoading}
              selectedUsers={selectedUsers}
              setSelectedUsers={setSelectedUsers}
            />

            <RoleSelector
              selectedRole={selectedRole}
              handleRoleChange={handleRoleChange}
              isLoadingRoles={isLoadingRoles}
              rolesData={rolesData}
              currentUserProjectRole={currentUserProjectRole}
            />
          </Stack>

          {/* Current project members section */}
          <ExistingUsers
            projectId={projectId}
            currentUserProjectRole={currentUserProjectRole}
            refreshProjectUsersAndRolesWrapper={refreshProjectUsersAndRolesWrapper}
            isLoadingRelatedUsers={isLoadingRelatedUsers || isRefreshingRelatedUsers}
            isLoadingRoles={isLoadingRoles}
            relatedUsersData={relatedUsersData}
            rolesData={rolesData}
          />
        </Box>

        <Divider sx={{ my: 1 }} />
      </DialogContent>

      <DialogActions sx={{ px: 3, pb: 3 }}>
        <Stack direction="row" spacing={2} justifyContent="space-between" width="100%">
          <Button onClick={onClose} variant="outlined" color="secondary">
            Close
          </Button>
          <Tooltip arrow title={submitDisabled ? 'Please select at least one member and a role' : ''}>
            <span>
              <Button onClick={handleShare} variant="contained" color="primary" disabled={submitDisabled}>
                Share
              </Button>
            </span>
          </Tooltip>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export default ShareModal;
