// third-party
import { configureStore } from '@reduxjs/toolkit';

// project import
import { dueDiligenceServer } from '../store/services/dueDiligenceServer';
import { auth0Proxy } from './services/auth0Proxy';
import { setupListeners } from '@reduxjs/toolkit/query';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import { combineReducers } from 'redux';
import { resetState } from './actions/logout';
import { permissions } from './services/permissions';
import { fileService } from './services/fileService';
const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['api'] // Ensure that only the api slice is persisted
};

import menu from './reducers/menu';

// ==============================|| REDUX TOOLKIT - MAIN STORE ||============================== //

const combinedReducers = combineReducers({
  [menu.reducerPath]: menu.reducer,
  [dueDiligenceServer.reducerPath]: dueDiligenceServer.reducer,
  [auth0Proxy.reducerPath]: auth0Proxy.reducer,
  [permissions.reducerPath]: permissions.reducer,
  [fileService.reducerPath]: fileService.reducer
  });
// I made this wrapper reducer so that I can reset the state of the store (on user logout)
// Otherwise, the users data is cached and can show up for another user on subsequent login.
// https://stackoverflow.com/questions/35622588/how-to-reset-the-state-of-a-redux-store
const rootReducer = (state, action) => {
    if (action.type === resetState.type) {
    state = undefined;
    // for all keys defined in your persistConfig(s)
    // storage.removeItem('persist:root');
    // storage.removeItem('persist:otherKey')
  }
  return combinedReducers(state, action);
}
const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      // For redux-persist:
      // serializableCheck: {
      //   ignoredActions: ['persist/PERSIST']
      // }
    }).concat(dueDiligenceServer.middleware, auth0Proxy.middleware, permissions.middleware, fileService.middleware)
});

// const persistor = persistStore(store);

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);

export { store };
