import CancelIcon from '@mui/icons-material/Cancel';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import {
  Autocomplete,
  Avatar,
  Checkbox,
  Chip,
  CircularProgress,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { MemberResponse } from '../../../../dd-client/types.gen';

interface UserSelectProps {
  selectableUsers: MemberResponse[];
  membersLoading: boolean;
  selectedUsers: MemberResponse[];
  setSelectedUsers: (users: MemberResponse[]) => void;
}

/**
 * UserSelect component that allows a user to select users from the organization.
 * It is used in the ShareModal component.
 *
 * @param organizationUsers - The users from the organization.
 * @param membersLoading - Whether the users are still loading.
 */
const UserSelect = ({
  selectableUsers,
  membersLoading,
  selectedUsers,
  setSelectedUsers
}: UserSelectProps) => {
  return (
    <Autocomplete
      multiple
      id="organization-members-autocomplete"
      options={selectableUsers}
      loading={membersLoading}
      value={selectedUsers}
      disableCloseOnSelect
      onChange={(event, newValue) => {
        setSelectedUsers(newValue);
      }}
      getOptionLabel={(option) => `${option.name} (${option.email})`}
      isOptionEqualToValue={(option, value) => option.user_id === value.user_id}
      renderOption={(props, option, { selected }) => {
        const { key, ...optionProps } = props;
        return (
          <li key={key} {...optionProps}>
            <Checkbox
              icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
              checkedIcon={<CheckBoxIcon fontSize="small" />}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            <Stack direction="row" spacing={1}>
              <Avatar src={option.picture} alt={option.name || ''}>
                {option.name.charAt(0)}
              </Avatar>
              <Stack direction="column" spacing={0}>
                <Typography variant="h6">{option.name}</Typography>
                <Typography variant="body2">({option.email})</Typography>
              </Stack>
            </Stack>
          </li>
        );
      }}
      renderTags={(value, getTagProps) =>
        value.map((option: MemberResponse, index) => {
          const { key, ...chipProps } = getTagProps({ index });
          return (
            <Chip
              key={key}
              label={option.name}
              size="small"
              {...chipProps}
              deleteIcon={
                <CancelIcon
                  onMouseDown={(event) => {
                    event.stopPropagation();
                    setSelectedUsers(selectedUsers.filter((user) => user.user_id !== option.user_id));
                  }}
                />
              }
            />
          );
        })
      }
      renderInput={(params) => (
        <TextField
          {...params}
          label="Select members"
          placeholder="Add organization members"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {membersLoading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            )
          }}
        />
      )}
      sx={{ flexGrow: 1 }}
    />
  );
};

export default UserSelect;
