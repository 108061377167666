// material-ui
import { createTheme } from '@mui/material/styles';

// ==============================|| DEFAULT THEME - PALETTE  ||============================== //

/**
 * Mode can be one of [
 *   'light',
 *   'light-medium-contrast',
 *   'light-high-contrast',
 *   'dark',
 *   'dark-medium-contrast',
 *   'dark-high-contrast'
 * ]
 **/
const Palette = (mode) => {
  const selectedScheme = paletteObj.schemes[mode];

  return createTheme({
    palette: {
      // Spread the selected scheme colors into the main palette
      // FIXME TODO: Maybe we don't actually want to do this? Is that what couses us to not have a normal 'mode'? Only light or dark?
      ...paletteObj,
      activeScheme: selectedScheme,
    }
  });
};

// ==============================|| PRESET THEME - THEME SELECTOR ||============================== //
const paletteObj = {
  description: 'TYPE: CUSTOM\nMaterial Theme Builder export 2024-03-11 06:09:49',
  seed: '#4861C8',
  coreColors: {
    primary: '#4861C8'
  },
  extendedColors: [],
  background: {
    paper: '#fff',
    default: '#fff'
  },
  primary: {
    main: '#4F5B92',
    light: '#6571AA',
    dark: '#333F74',
    contrastText: '#fff'
  },
  secondary: {
    main: '#5A5D72',
    light: '#707389',
    dark: '#3E4255',
    contrastText: '#fff'

  },
  tertiary: {
    main: '#76546E',
    light: '#FFD7F3',
    dark: '#2C1229',
    contrastText: '#fff'
  },
  error: {
    main: '#d32f2f',
    light: '#ef5350',
    dark: '#c62828',
    contrastText: '#fff'
  },
  warning: {
    main: '#ed6c02',
    light: '#ff9800',
    dark: '#e65100',
    contrastText: '#fff'
  },
  info: {
    main: '#FBF8FD',
    light: '#FFFFFF',
    dark: '#DDE1FF',
    contrastText: '#171B2C'
  },
  success: {
    main: '#2e7d32',
    light: '#4caf50',
    dark: '#1b5e20',
    contrastText: '#fff'
  },
  schemes: {
    light: {
      primary: '#4F5B92',
      surfaceTint: '#4F5B92',
      onPrimary: '#FFFFFF',
      primaryContainer: '#DDE1FF',
      onPrimaryContainer: '#07164B',
      inversePrimary: '#B8C4FF',
      primaryFixed: '#DDE1FF',
      onPrimaryFixed: '#07164B',
      primaryFixedDim: '#B8C4FF',
      onPrimaryFixedVariant: '#374379',

      secondary: '#5A5D72',
      onSecondary: '#FFFFFF',
      secondaryContainer: '#DFE1F9',
      onSecondaryContainer: '#171B2C',
      secondaryFixed: '#DFE1F9',
      onSecondaryFixed: '#171B2C',
      secondaryFixedDim: '#C2C5DD',
      onSecondaryFixedVariant: '#424659',

      tertiary: '#76546E',
      onTertiary: '#FFFFFF',
      tertiaryContainer: '#FFD7F3',
      onTertiaryContainer: '#2C1229',
      tertiaryFixed: '#FFD7F3',
      onTertiaryFixed: '#2C1229',
      tertiaryFixedDim: '#E4BAD9',
      onTertiaryFixedVariant: '#5C3D56',

      error: '#BA1A1A',
      onError: '#FFFFFF',
      errorContainer: '#FFDAD6',
      onErrorContainer: '#410002',

      background: '#FBF8FF',
      onBackground: '#1B1B21',

      surface: '#FBF8FF',
      onSurface: '#1B1B21',
      surfaceDim: '#DBD9E0',
      surfaceBright: '#FBF8FF',
      surfaceContainerLowest: '#FFFFFF',
      surfaceContainerLow: '#F5F2FA',
      surfaceContainer: '#EFEDF4',
      surfaceContainerHigh: '#E9E7EF',
      surfaceContainerHighest: '#E3E1E9',
      surfaceVariant: '#E2E1EC',
      onSurfaceVariant: '#45464F',

      outline: '#767680',
      outlineVariant: '#C6C5D0',

      shadow: '#000000',
      scrim: '#000000',

      inverseSurface: '#303036',
      inverseOnSurface: '#F2F0F7'
    },
    'light-medium-contrast': {
      primary: '#333F74',
      surfaceTint: '#4F5B92',
      onPrimary: '#FFFFFF',
      primaryContainer: '#6571AA',
      onPrimaryContainer: '#FFFFFF',
      secondary: '#3E4255',
      onSecondary: '#FFFFFF',
      secondaryContainer: '#707389',
      onSecondaryContainer: '#FFFFFF',
      tertiary: '#583952',
      onTertiary: '#FFFFFF',
      tertiaryContainer: '#8D6985',
      onTertiaryContainer: '#FFFFFF',
      error: '#8C0009',
      onError: '#FFFFFF',
      errorContainer: '#DA342E',
      onErrorContainer: '#FFFFFF',
      background: '#FBF8FF',
      onBackground: '#1B1B21',
      surface: '#FBF8FF',
      onSurface: '#1B1B21',
      surfaceVariant: '#E2E1EC',
      onSurfaceVariant: '#41424B',
      outline: '#5E5E67',
      outlineVariant: '#7A7A83',
      shadow: '#000000',
      scrim: '#000000',
      inverseSurface: '#303036',
      inverseOnSurface: '#F2F0F7',
      inversePrimary: '#B8C4FF',
      primaryFixed: '#6571AA',
      onPrimaryFixed: '#FFFFFF',
      primaryFixedDim: '#4D5990',
      onPrimaryFixedVariant: '#FFFFFF',
      secondaryFixed: '#707389',
      onSecondaryFixed: '#FFFFFF',
      secondaryFixedDim: '#585B6F',
      onSecondaryFixedVariant: '#FFFFFF',
      tertiaryFixed: '#8D6985',
      onTertiaryFixed: '#FFFFFF',
      tertiaryFixedDim: '#73516C',
      onTertiaryFixedVariant: '#FFFFFF',
      surfaceDim: '#DBD9E0',
      surfaceBright: '#FBF8FF',
      surfaceContainerLowest: '#FFFFFF',
      surfaceContainerLow: '#F5F2FA',
      surfaceContainer: '#EFEDF4',
      surfaceContainerHigh: '#E9E7EF',
      surfaceContainerHighest: '#E3E1E9'
    },
    'light-high-contrast': {
      primary: '#101D52',
      surfaceTint: '#4F5B92',
      onPrimary: '#FFFFFF',
      primaryContainer: '#333F74',
      onPrimaryContainer: '#FFFFFF',
      secondary: '#1E2133',
      onSecondary: '#FFFFFF',
      secondaryContainer: '#3E4255',
      onSecondaryContainer: '#FFFFFF',
      tertiary: '#341830',
      onTertiary: '#FFFFFF',
      tertiaryContainer: '#583952',
      onTertiaryContainer: '#FFFFFF',
      error: '#4E0002',
      onError: '#FFFFFF',
      errorContainer: '#8C0009',
      onErrorContainer: '#FFFFFF',
      background: '#FBF8FF',
      onBackground: '#1B1B21',
      surface: '#FBF8FF',
      onSurface: '#000000',
      surfaceVariant: '#E2E1EC',
      onSurfaceVariant: '#22232B',
      outline: '#41424B',
      outlineVariant: '#41424B',
      shadow: '#000000',
      scrim: '#000000',
      inverseSurface: '#303036',
      inverseOnSurface: '#FFFFFF',
      inversePrimary: '#E9EBFF',
      primaryFixed: '#333F74',
      onPrimaryFixed: '#FFFFFF',
      primaryFixedDim: '#1C295D',
      onPrimaryFixedVariant: '#FFFFFF',
      secondaryFixed: '#3E4255',
      onSecondaryFixed: '#FFFFFF',
      secondaryFixedDim: '#282C3E',
      onSecondaryFixedVariant: '#FFFFFF',
      tertiaryFixed: '#583952',
      onTertiaryFixed: '#FFFFFF',
      tertiaryFixedDim: '#40233B',
      onTertiaryFixedVariant: '#FFFFFF',
      surfaceDim: '#DBD9E0',
      surfaceBright: '#FBF8FF',
      surfaceContainerLowest: '#FFFFFF',
      surfaceContainerLow: '#F5F2FA',
      surfaceContainer: '#EFEDF4',
      surfaceContainerHigh: '#E9E7EF',
      surfaceContainerHighest: '#E3E1E9'
    },
    dark: {
      primary: '#B8C4FF',
      surfaceTint: '#B8C4FF',
      onPrimary: '#202C61',
      primaryContainer: '#374379',
      onPrimaryContainer: '#DDE1FF',
      secondary: '#C2C5DD',
      onSecondary: '#2C2F42',
      secondaryContainer: '#424659',
      onSecondaryContainer: '#DFE1F9',
      tertiary: '#E4BAD9',
      onTertiary: '#44273F',
      tertiaryContainer: '#5C3D56',
      onTertiaryContainer: '#FFD7F3',
      error: '#FFB4AB',
      onError: '#690005',
      errorContainer: '#93000A',
      onErrorContainer: '#FFDAD6',
      background: '#121318',
      onBackground: '#E3E1E9',
      surface: '#121318',
      onSurface: '#E3E1E9',
      surfaceVariant: '#45464F',
      onSurfaceVariant: '#C6C5D0',
      outline: '#90909A',
      outlineVariant: '#45464F',
      shadow: '#000000',
      scrim: '#000000',
      inverseSurface: '#E3E1E9',
      inverseOnSurface: '#303036',
      inversePrimary: '#4F5B92',
      primaryFixed: '#DDE1FF',
      onPrimaryFixed: '#07164B',
      primaryFixedDim: '#B8C4FF',
      onPrimaryFixedVariant: '#374379',
      secondaryFixed: '#DFE1F9',
      onSecondaryFixed: '#171B2C',
      secondaryFixedDim: '#C2C5DD',
      onSecondaryFixedVariant: '#424659',
      tertiaryFixed: '#FFD7F3',
      onTertiaryFixed: '#2C1229',
      tertiaryFixedDim: '#E4BAD9',
      onTertiaryFixedVariant: '#5C3D56',
      surfaceDim: '#121318',
      surfaceBright: '#38393F',
      surfaceContainerLowest: '#0D0E13',
      surfaceContainerLow: '#1B1B21',
      surfaceContainer: '#1F1F25',
      surfaceContainerHigh: '#292A2F',
      surfaceContainerHighest: '#34343A'
    },
    'dark-medium-contrast': {
      primary: '#BEC8FF',
      surfaceTint: '#B8C4FF',
      onPrimary: '#011046',
      primaryContainer: '#828EC8',
      onPrimaryContainer: '#000000',
      secondary: '#C7C9E1',
      onSecondary: '#121526',
      secondaryContainer: '#8C8FA6',
      onSecondaryContainer: '#000000',
      tertiary: '#E9BEDD',
      onTertiary: '#260C23',
      tertiaryContainer: '#AB85A2',
      onTertiaryContainer: '#000000',
      error: '#FFBAB1',
      onError: '#370001',
      errorContainer: '#FF5449',
      onErrorContainer: '#000000',
      background: '#121318',
      onBackground: '#E3E1E9',
      surface: '#121318',
      onSurface: '#FCFAFF',
      surfaceVariant: '#45464F',
      onSurfaceVariant: '#CACAD4',
      outline: '#A2A2AC',
      outlineVariant: '#82828C',
      shadow: '#000000',
      scrim: '#000000',
      inverseSurface: '#E3E1E9',
      inverseOnSurface: '#292A2F',
      inversePrimary: '#38457A',
      primaryFixed: '#DDE1FF',
      onPrimaryFixed: '#000B3C',
      primaryFixedDim: '#B8C4FF',
      onPrimaryFixedVariant: '#263267',
      secondaryFixed: '#DFE1F9',
      onSecondaryFixed: '#0C1021',
      secondaryFixedDim: '#C2C5DD',
      onSecondaryFixedVariant: '#323548',
      tertiaryFixed: '#FFD7F3',
      onTertiaryFixed: '#20071E',
      tertiaryFixedDim: '#E4BAD9',
      onTertiaryFixedVariant: '#4A2C45',
      surfaceDim: '#121318',
      surfaceBright: '#38393F',
      surfaceContainerLowest: '#0D0E13',
      surfaceContainerLow: '#1B1B21',
      surfaceContainer: '#1F1F25',
      surfaceContainerHigh: '#292A2F',
      surfaceContainerHighest: '#34343A'
    },
    'dark-high-contrast': {
      primary: '#FCFAFF',
      surfaceTint: '#B8C4FF',
      onPrimary: '#000000',
      primaryContainer: '#BEC8FF',
      onPrimaryContainer: '#000000',
      secondary: '#FCFAFF',
      onSecondary: '#000000',
      secondaryContainer: '#C7C9E1',
      onSecondaryContainer: '#000000',
      tertiary: '#FFF9FA',
      onTertiary: '#000000',
      tertiaryContainer: '#E9BEDD',
      onTertiaryContainer: '#000000',
      error: '#FFF9F9',
      onError: '#000000',
      errorContainer: '#FFBAB1',
      onErrorContainer: '#000000',
      background: '#121318',
      onBackground: '#E3E1E9',
      surface: '#121318',
      onSurface: '#FFFFFF',
      surfaceVariant: '#45464F',
      onSurfaceVariant: '#FCFAFF',
      outline: '#CACAD4',
      outlineVariant: '#CACAD4',
      shadow: '#000000',
      scrim: '#000000',
      inverseSurface: '#E3E1E9',
      inverseOnSurface: '#000000',
      inversePrimary: '#19265A',
      primaryFixed: '#E3E5FF',
      onPrimaryFixed: '#000000',
      primaryFixedDim: '#BEC8FF',
      onPrimaryFixedVariant: '#011046',
      secondaryFixed: '#E3E5FE',
      onSecondaryFixed: '#000000',
      secondaryFixedDim: '#C7C9E1',
      onSecondaryFixedVariant: '#121526',
      tertiaryFixed: '#FFDDF4',
      onTertiaryFixed: '#000000',
      tertiaryFixedDim: '#E9BEDD',
      onTertiaryFixedVariant: '#260C23',
      surfaceDim: '#121318',
      surfaceBright: '#38393F',
      surfaceContainerLowest: '#0D0E13',
      surfaceContainerLow: '#1B1B21',
      surfaceContainer: '#1F1F25',
      surfaceContainerHigh: '#292A2F',
      surfaceContainerHighest: '#34343A'
    }
  },
  palettes: {
    primary: {
      0: '#000000',
      5: '#000B3B',
      10: '#001355',
      15: '#001B6D',
      20: '#002486',
      25: '#0E2F98',
      30: '#203CA3',
      35: '#2F49B0',
      40: '#3C56BC',
      50: '#576FD7',
      60: '#718AF3',
      70: '#93A6FF',
      80: '#B8C4FF',
      90: '#DDE1FF',
      95: '#F0EFFF',
      98: '#FBF8FF',
      99: '#FEFBFF',
      100: '#FFFFFF'
    },
    secondary: {
      0: '#000000',
      5: '#0B0F25',
      10: '#161A30',
      15: '#20253B',
      20: '#2B2F46',
      25: '#363A51',
      30: '#41465D',
      35: '#4D5169',
      40: '#595D76',
      50: '#717690',
      60: '#8B8FAA',
      70: '#A6AAC6',
      80: '#C1C5E2',
      90: '#DDE1FF',
      95: '#F0EFFF',
      98: '#FBF8FF',
      99: '#FEFBFF',
      100: '#FFFFFF'
    },
    tertiary: {
      0: '#000000',
      5: '#230420',
      10: '#2F0F2C',
      15: '#3B1936',
      20: '#472442',
      25: '#532F4D',
      30: '#603A59',
      35: '#6D4665',
      40: '#7A5172',
      50: '#95698B',
      60: '#B083A6',
      70: '#CC9DC1',
      80: '#EAB7DD',
      90: '#FFD7F3',
      95: '#FFEBF7',
      98: '#FFF7F9',
      99: '#FFFBFF',
      100: '#FFFFFF'
    },
    neutral: {
      0: '#000000',
      5: '#101114',
      10: '#1B1B1F',
      15: '#252529',
      20: '#303034',
      25: '#3B3B3F',
      30: '#47464A',
      35: '#525256',
      40: '#5F5E62',
      50: '#77767A',
      60: '#919094',
      70: '#ACAAAF',
      80: '#C8C6CA',
      90: '#E4E1E6',
      95: '#F2F0F4',
      98: '#FBF8FD',
      99: '#FEFBFF',
      100: '#FFFFFF'
    },
    'neutral-variant': {
      0: '#000000',
      5: '#0F1118',
      10: '#1A1B23',
      15: '#24252D',
      20: '#2F3038',
      25: '#3A3B43',
      30: '#45464F',
      35: '#51525B',
      40: '#5D5E67',
      50: '#767680',
      60: '#90909A',
      70: '#ABAAB4',
      80: '#C6C5D0',
      90: '#E2E1EC',
      95: '#F1F0FA',
      98: '#FBF8FF',
      99: '#FEFBFF',
      100: '#FFFFFF'
    }
  }
};
export default Palette;
