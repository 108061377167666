import { createApi } from '@reduxjs/toolkit/query/react';
import {
  InvitationsRequest,
  InvitationsRequestResponse,
  InvitationsResponse,
  MembersResponse,
  RolesResponse,
  UserBaseRoleResponse,
  UserResponse
} from '../../dd-client/types.gen';

import { getAccessToken } from './utils/accessToken';
/**
 * Endpoints for our Auth0 Proxy API
 *
 * The Proxy API exists to authenticate requests to the Auth0 Management API.
 * Without it, a FE application such as this one would only have access to
 * the subset of the Auth0 Management API for managing the current user
 * https://auth0.com/docs/secure/tokens/access-tokens/management-api-access-tokens/get-management-api-tokens-for-single-page-applications
 *
 */
export const auth0Proxy = createApi({
  reducerPath: 'auth0Proxy',
  baseQuery: getAccessToken,
  endpoints: (builder) => ({
    getOrganizationMembers: builder.query<MembersResponse, string>({
      query: () => `/account_management/members?include=roles`
    }),
    getUser: builder.query<UserResponse, string>({
      query: (userId) => `/account_management/users/${userId}`
    }),
    getOrganizationInvitations: builder.query<InvitationsResponse, string>({
      query: () => `/account_management/invitations`
    }),
    postOrganizationInvitations: builder.mutation<
      InvitationsRequestResponse,
      { body: InvitationsRequest }
    >({
      query: ({ body }) => ({
        url: `/account_management/invitations`,
        method: 'POST',
        body
      })
    }),
    getRoles: builder.query<RolesResponse, {excludeSpecialRoles?: boolean}>({
      query: ({excludeSpecialRoles = false}) => {
        const queryParams = new URLSearchParams();
        if (excludeSpecialRoles) {
          queryParams.append('exclude_special_roles', 'true');
        }
        return `/account_management/roles?${queryParams.toString()}`;
      }
    }),
    getUserBaseRole: builder.query<UserBaseRoleResponse, { userId: string }>({
      query: ({ userId }) => `/account_management/users/${userId}/base_role`
    })
  })
});

// Export auto generated hooks
// https://redux-toolkit.js.org/rtk-query/api/created-api/hooks
export const {
  useGetOrganizationMembersQuery,
  useGetUserQuery,
  useGetOrganizationInvitationsQuery,
  usePostOrganizationInvitationsMutation,
  useGetRolesQuery,
  useGetUserBaseRoleQuery
} = auth0Proxy;
