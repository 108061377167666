// Framework
import React from 'react';
import { LicenseInfo } from '@mui/x-license';
import { StrictMode } from 'react';

// Components
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';
import { sec } from './security';
import { ErrorBoundary as SentryErrorBoundary } from '@sentry/react';
import ErrorFallback from 'ErrorFallback';
import Auth0ProviderWithNavigate from 'Auth0ProviderWithNavigate';

// Hooks
import { initFirebase } from './firebase/firebase';
import './index.css';
import { createRoot } from 'react-dom/client';
import { store } from './store';
import reportWebVitals from './reportWebVitals';
import { useNavigate } from 'react-router-dom';

// Config
import envConfig from 'config/config';
import { StreamChat } from 'stream-chat';

// Dependencies
import { initSentry } from './sentry'; // Initialize Sentry

LicenseInfo.setLicenseKey(
  'f03a15da3386ebc4666c465aa659c0a3Tz0xMDAyODIsRT0xNzYwODg5NzY5MDAwLFM9cHJlbWl1bSxMTT1zdWJzY3JpcHRpb24sUFY9aW5pdGlhbCxLVj0y' // Premium
);

initFirebase();
if (process.env.REACT_APP_ENV === 'production') {
  initSentry();
}
// import { PersistGate } from 'redux-persist/integration/react';
// <PersistGate loading={null} persistor={persistor}>App</PersistGate>

// ==============================|| MAIN - REACT DOM RENDER  ||============================== //

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  <StrictMode>
    <ReduxProvider store={store}>
      <BrowserRouter>
        {/* We could add `showDialog` to the fallback component */}
        <SentryErrorBoundary fallback={ErrorFallback}>
          <Auth0ProviderWithNavigate>
            <App />
          </Auth0ProviderWithNavigate>
        </SentryErrorBoundary>
      </BrowserRouter>
    </ReduxProvider>
  </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
