// Framework

// Components
import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';
import { sec } from './security';

// Hooks
import { useNavigate } from 'react-router-dom';
import './index.css';

// Config
import envConfig from './config/config';

/**
 * Provider for all Auth0 functionality
 * This compoment must be inside a Router component
 *
 * @param {{ children: any; }} param0
 * @returns {*}
 */
const Auth0ProviderWithNavigate = ({ children }: any) => {
  const navigate = useNavigate();

  const domain = envConfig.auth0Config.domain;
  const clientId = envConfig.auth0Config.clientId;
  const redirectUri = envConfig.auth0Config.redirectUri;
  const audience = envConfig.auth0Config.audience;

  /**
   * Redirect to the URL the user was navigating to before they were redirected to Auth0 for login
   *
   * How it works:
   * - After login Auth0 redirects the user to redirect_uri with a `state` and `code` query param.
   *   See Config.auth0Config.redirectUri for the value
   * - Auth0ProviderWithNavigate renders/runs and triggers onRedirectCallback
   *
   * Warnings:
   * 1. Auth0 navigates to redirect_uri with `code` and `state` query params:
   *    e.g. `?code=-I-ie7hM6RO6bRmNQK5YSI8GMo7WFOXEhnMpyFOn9Xfpt&state=RHRfYUR4RkFTOX5DUG5%2BVTU4RVYxdjhEZFo0UGZ2dTZYT2tWZngzd1dmZw%3D%3D`
   *    If these params are _not_ removed (say by navigate()) and the user reloads the page with those params present,
   *    they will be redirected to the Auth0 login page.
   * 2. I have heard that navigating to the redirect_uri must result in a full page load.
   *    If it does not (and, say, results in a redirect, like our `/` path does),
   *    Auth0 will decide that the user is not logged in and boot them back to the login page.
   *    Issue: https://github.com/auth0/auth0-react/issues/82#issuecomment-711479502
   *    We do not seem to have this issue.
   */
  const onRedirectCallback = (appState: any) => {
    navigate(appState?.returnTo || '/');
  };

  if (!(domain && clientId && redirectUri && audience)) {
    return null;
  }

  return (
    <Auth0Provider
      useRefreshTokens={true} // https://auth0.com/docs/secure/tokens/refresh-tokens/use-refresh-tokens
      useRefreshTokensFallback={true} // https://auth0.github.io/auth0-react/interfaces/Auth0ProviderOptions.html#useRefreshTokens
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        audience,
        // `redirect_uri`: The URL in your application where Auth0 redirects the user after they have authenticated.
        // Must match the URL in the Allowed Callback URLs field in your Auth0 Application settings
        redirect_uri: redirectUri,
        // `scope`: The set of all permissions this application can have on behalf of a user. Is a superset of those defined in any call to getAccessTokenSilently
        // read:current_user allows the app to read the user's profile from their identity provider
        // offline_access allows the app to get a refresh token, as per https://auth0.com/docs/secure/tokens/refresh-tokens/get-refresh-tokens
        scope: 'openid profile email read:current_user offline_access'
      }}
      onRedirectCallback={onRedirectCallback}
      cacheLocation="localstorage"
    >
      <HackToExposeLoginHookAsPureFn>{children}</HackToExposeLoginHookAsPureFn>
    </Auth0Provider>
  );
};

const HackToExposeLoginHookAsPureFn = ({ children }: any) => {
  // Why this exists: https://github.com/reduxjs/redux-toolkit/issues/1331#issuecomment-1080901326
  const { getAccessTokenSilently, getIdTokenClaims } = useAuth0();
  // set getAccessTokenSilently in global module to reuse it outside a React component
  sec.setAccessTokenSilently(getAccessTokenSilently);

  return children;
};

export default Auth0ProviderWithNavigate;