import HomeIcon from '@mui/icons-material/Home';
import { Box, Breadcrumbs, Link } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useProjectContext } from 'pages/project/ProjectProvider';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { auth0Proxy } from '../../store/services/auth0Proxy';
import { useEffect } from 'react';

function capitalize(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

// https://stackoverflow.com/questions/175739/how-can-i-check-if-a-string-is-a-valid-number
const isNumber = (str) => !isNaN(str) && !isNaN(parseFloat(str));

const BreadcrumbNav = () => {
  const location = useLocation();
  const { pathname } = location;
  const pathnames = pathname.split('/').filter((x) => x);
  const theme = useTheme();
  // Get the project name
  const isProjectDetailsRoute = pathnames.length > 1 && pathnames[0] === 'projects' && isNumber(pathnames[1]);
  const isUserDetailsRoute = pathnames.length > 2 && pathnames[0] === 'admin' && pathnames[1] === 'users' && pathnames[2];
  const { project } = useProjectContext();
  const [getUser, { data: user }] = auth0Proxy.endpoints.getUser.useLazyQuery();
  useEffect(() => {
    if (isUserDetailsRoute) {
      getUser(pathnames[2]);
    }
  }, [isUserDetailsRoute]);
  return (
    <Box // without this container the breadcrumbs get squished vertically under the header in a strange way. Overflow: visible also fixes it
    >
      <Breadcrumbs aria-label="breadcrumb">
        <Link
          color={theme.palette.text.secondary}
          component={RouterLink}
          to="/"
          sx={{ display: 'flex', alignItems: 'center' }}
        >
          <HomeIcon fontSize="inherit" />
        </Link>
        {pathnames.map((name, index) => {
          const routeTo = `/${pathnames.slice(0, index + 1).join('/')}`;
          let content = capitalize(name);
          // For routes like /projects/:id, show the project name in the breadcrumb instead of the id
          if (index == 1 && isProjectDetailsRoute && project?.name) {
            content = project.name;
          }
          // For user detail routes, show the user's name instead of the ID
          if (index === 2 && isUserDetailsRoute && user?.name) {
            content = user.name;
          }
          return (
            <Link key={name} component={RouterLink} to={routeTo} color={theme.palette.text.secondary}>
              {content}
            </Link>
          );
        })}
      </Breadcrumbs>
    </Box>
  );
};

export default BreadcrumbNav;
