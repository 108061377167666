import { createApi } from '@reduxjs/toolkit/query/react';
import { getAccessToken } from './utils/accessToken';

import { UploadUrlResponseModel, UploadUrlRequestModel } from 'dd-client/types.gen';

export const fileService = createApi({
  reducerPath: 'fileService',
  baseQuery: getAccessToken,
  endpoints: (builder) => ({
    getUploadUrl: builder.mutation<UploadUrlResponseModel, {projectId: number, body: UploadUrlRequestModel}>({
      query: ({ projectId, body }) => ({
        url: `projects/${projectId}/upload-url`,
        method: 'POST',
        body
      })
    })
  })
});

export const { useGetUploadUrlMutation } = fileService;
