import projects from './projects';
import admin from './admin';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  // import and add items to this list to add them to the sidebar
  items: [projects, admin]
};

export default menuItems;
