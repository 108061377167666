import React, { createContext, ReactNode, useContext, useState } from 'react';
import ShareModal from './modal/ShareModal';

interface ShareModalContextType {
  openShareModal: (projectId: string, projectName: string) => void;
  closeShareModal: () => void;
}

const ShareModalContext = createContext<ShareModalContextType | undefined>(undefined);

interface ShareModalProviderProps {
  children: ReactNode;
}

/**
 * Provider component that wraps your app and makes the share modal
 * available throughout your app
 */
export const ShareModalProvider: React.FC<ShareModalProviderProps> = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [projectId, setProjectId] = useState('');
  const [projectName, setProjectName] = useState('');

  const openShareModal = (id: string, name: string) => {
    setProjectId(id);
    setProjectName(name);
    setIsOpen(true);
  };

  const closeShareModal = () => {
    setIsOpen(false);
  };

  return (
    <ShareModalContext.Provider value={{ openShareModal, closeShareModal }}>
      {children}
      <ShareModal open={isOpen} onClose={closeShareModal} projectId={projectId} projectName={projectName} />
    </ShareModalContext.Provider>
  );
};

/**
 * Hook that lets you access the share modal functionality
 */
export const useShareModal = (): ShareModalContextType => {
  const context = useContext(ShareModalContext);

  if (context === undefined) {
    throw new Error('useShareModal must be used within a ShareModalProvider');
  }

  return context;
};
