// assets
import FolderIcon from '@mui/icons-material/Folder';
import PeopleIcon from '@mui/icons-material/People';
import ArchiveIcon from '@mui/icons-material/Archive';
// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

// Should mirror routes in src/routes/index.js
const projects = {
  title: 'Projects',
  type: 'group',
  id: 'projects-group',
  children: [
    {
      title: 'Projects',
      type: 'item',
      id: 'projects',
      url: '/projects',
      icon: FolderIcon
    },
    {
      title: 'Archive',
      type: 'item',
      id: 'projects_archive',
      url: '/projects_archive',
      icon: ArchiveIcon
    }
  ]
};



export default projects;
